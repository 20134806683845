import React from 'react'
import Col from '../../Col'
import Row from '../../Row'
import SummaryTable from '../SummaryTable'
import { constants } from '../../../../common/schemaConfig'
import { checkAreBaseCurvesValid } from '../../../../common/basecurve'

import { appTypeConfig, orderTypes } from '../../../../common/config'
import { renderWithSign, renderFormattedNumber, isSomeFieldFilled } from './utils'

const numberColumnWidth = '1rem' // todo, asi není třeba
const numberColumnWideWidth = '1rem'

const tableGap = {
  colProps: { width: '2rem' },
  renderValue: () => null,
}

const CorrectionsSummary = props => {
  const { t, isCreatingOrder, currentLensL, currentLensR } = props
  let { values } = props
  const hasLensWithDegression =
    values.hasLensWithDegression || currentLensL?.withDegression || currentLensR?.withDegression

  const deleteBaseCurveIfNotUsed = () => {
    const { values, currentLensL, currentLensR, vca } = props
    const newValues = { ...values }
    const areBaseCurvesValid = checkAreBaseCurvesValid({
      values,
      currentLensL,
      currentLensR,
      selectedVca: vca,
    })
    if (!areBaseCurvesValid.R) newValues.baseCurveR = null
    if (!areBaseCurvesValid.L) newValues.baseCurveL = null
    if (values.isLeftLensSameAsRight && !areBaseCurvesValid.R) newValues.baseCurveL = null
    return newValues
  }

  if (isCreatingOrder && appTypeConfig.isBaseCurveSelectEnabled) {
    values = deleteBaseCurveIfNotUsed({ values, currentLensL, currentLensR })
  }

  const isBaseDisabled = !isSomeFieldFilled([constants.baseCurve], values)
  const isCentrationDisabled =
    !isSomeFieldFilled([constants.pd, constants.height], values) ||
    (values.orderType === orderTypes.DIAMETER_ONLY && !values.isEdgingWithoutShape)

  const isCentrationHidden =
    values.orderType === orderTypes.DIAMETER_ONLY && !values.isEdgingWithoutShape

  const isPrismDisabled = !isSomeFieldFilled(
    [constants.prism1, constants.base1, constants.prism2, constants.base2],
    values,
  )

  const isDecentrationDisabled = !isSomeFieldFilled([constants.decX, constants.decY], values)

  return (
    <SummaryTable
      title={t('correction')}
      {...props}
      render={children => (
        <Row large>
          <Col>{children}</Col>
        </Row>
      )}
      values={values}
      isPair
      disableR={!values.rightLensEnabled}
      disableL={!values.leftLensEnabled}
      compactColumns
      data={[
        {
          header: t('sphere'),
          fieldName: constants.sphere,
          renderValue: renderWithSign,
          colProps: {
            minWidth: numberColumnWidth,
          },
        },
        {
          header: t('cylinder'),
          fieldName: constants.cylinder,
          renderValue: renderWithSign,
          colProps: {
            minWidth: numberColumnWidth,
          },
        },
        {
          header: t('axis'),
          fieldName: constants.axis,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
          },
        },
        {
          header: hasLensWithDegression ? t('degression') : t('addition'),
          fieldName: constants.addition,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
          },
        },
        tableGap,
        {
          header: t('pd'),
          fieldName: constants.pd,
          renderValue: renderFormattedNumber,
          isValueHidden: isCentrationHidden,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isCentrationDisabled,
          },
        },
        {
          header: t('height'),
          fieldName: constants.height,
          renderValue: renderFormattedNumber,
          isValueHidden: isCentrationHidden,
          colProps: {
            minWidth: numberColumnWideWidth,
            isDisabled: isCentrationDisabled,
          },
        },
        tableGap,
        {
          header: t('prism1'),
          fieldName: constants.prism1,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isPrismDisabled,
          },
        },
        {
          header: t('base1'),
          fieldName: constants.base1,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isPrismDisabled,
          },
        },
        {
          header: t('prism2'),
          fieldName: constants.prism2,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isPrismDisabled,
          },
        },
        {
          header: t('base2'),
          fieldName: constants.base2,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWideWidth,
            isDisabled: isPrismDisabled,
          },
        },
        appTypeConfig.isDecentrationEnabled && tableGap,
        appTypeConfig.isDecentrationEnabled && {
          header: t('decX'),
          fieldName: constants.decX,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isDecentrationDisabled,
          },
        },
        appTypeConfig.isDecentrationEnabled && {
          header: t('decY'),
          fieldName: constants.decY,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isDecentrationDisabled,
          },
        },
        appTypeConfig.isLensBaseEnabled && tableGap,
        appTypeConfig.isLensBaseEnabled && {
          header: t('base'),
          fieldName: constants.baseCurve,
          renderValue: renderFormattedNumber,
          colProps: {
            minWidth: numberColumnWidth,
            isDisabled: isBaseDisabled,
          },
        },
      ]}
    />
  )
}

export default CorrectionsSummary
