import React from 'react'
import ErrorScreen from './ErrorScreen'

// eslint-disable-next-line
class ErrorFallback extends React.Component {
  state = {
    issueId: null,
  }

  componentDidMount() {
    this.interval = setInterval(this.checkForIssueId, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  checkForIssueId = () => {
    this.setState({ issueId: window.issueId })
  }

  render() {
    const { issueId } = this.state

    return (
      <ErrorScreen issueId={issueId} />
    )
  }
}

export default ErrorFallback
