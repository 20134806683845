import { useMemo } from 'react'

// Helper function for non-hook usage
export const isMobileDevice = () => {
  // Check if window is available (for SSR compatibility)
  if (typeof window === 'undefined') return false

  // Check user agent for mobile devices (excluding tablets)
  const { userAgent } = navigator

  // Check for mobile devices
  const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  )

  // Exclude tablets
  const isTablet =
    /iPad|Tablet|PlayBook/i.test(userAgent) ||
    (/Android/i.test(userAgent) && !/Mobile/i.test(userAgent))

  return isMobile && !isTablet
}

// Hook version that memoizes the result
export const useIsMobile = () => {
  return useMemo(() => {
    return isMobileDevice()
  }, []) // Empty deps array since navigator.userAgent won't change during session
}
