import React, { useEffect, useState } from 'react'
import { useRouteError } from 'react-router-dom'
import ErrorScreen from './ErrorScreen'

const RouterErrorFallback = () => {
  const error = useRouteError()
  const [issueId, setIssueId] = useState(null)

  useEffect(() => {
    if (error) {
      console.error(error)
      console.notifyError(error, { message: 'BSOD', isRouterFallback: true })
    }
  }, [error])

  useEffect(() => {
    const checkIssueId = () => {
      if (window.issueId && !issueId) {
        setIssueId(window.issueId)
      }
    }

    const interval = setInterval(checkIssueId, 1000)
    return () => clearInterval(interval)
  }, [setIssueId]) // eslint-disable-line react-hooks/exhaustive-deps

  return <ErrorScreen issueId={issueId} />
}

export default RouterErrorFallback
