import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { HiddenInPrint } from '.'
import { Visualiser } from './vca'
import { Summary } from './order'
import { getMultifocalConfig, getBifocalConfig } from '../../common/lib/vca'
import { orderTypes } from '../../common/config'
import { roundNumber } from '../../common/lib/numbers'

const Table = styled.div`
  ${() => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;

    @media print {
      page-break-inside: avoid;
    }
  `}
`

const TableCell = styled.div`
  ${({ theme: { colors }, borderLeft, align, textAlign = "left", bold, width, withBackground, large }) => css`
    padding: 1rem 1rem 1rem 1rem;
    ${borderLeft &&
    `
    border-left: 1px solid ${colors.groupBorder};
  `}
    justify-content: ${align};
    text-align: ${textAlign};
    align-items: center;
    font-size: ${large ? 1.6 : 1.4}rem;
    color: ${colors.text2};
    display: flex;
    flex-direction: row;
    font-weight: ${bold && 'bold'};
    width: ${width || '22rem'};
    background: ${withBackground && colors.comparingTableBackground};
    :last-child {
      border: none;
    }
  `}
`

const TableRow = styled.div`
  ${({ theme: { colors }, withBackground }) => css`
    border-bottom: 1px solid ${colors.groupBorder};
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: stretch;
    background: ${withBackground && colors.comparingTableBackground};

    :last-child {
      border: none;
    }
  `}
`

const TableCellComparedMessage = styled.span`
  ${() => css`
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0 0 0 1rem;
    font-style: italic;
  `}
`

const Dot = styled.div`
  ${({ compared }) => css`
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100rem;
    margin-right: 1rem;
    @media print {
      border-style: solid;
      border-width: ${compared ? '0.15rem' : '0.6rem'};
      border-color: ${compared ? 'green' : 'orange'};
    }
  `}
`

const PrintContent = styled.div`
  ${({ disabled }) => css`
    display: none;
    @media print {
      /* okraje v index.html jsou nastaveny na 1cm, 19cm je tedy obsah. */
      max-width: 19cm;
      overflow: hidden;
      display: ${disabled ? 'none' : 'block'};
    }
  `}
`

// TODO - funkce jsou zkopírované z orderStep3 - bylo by fajn je někam vyndat.
const getCalcValue = (key, calculatedVca, calculatedComparedVca) => ({
  original: (calculatedVca && calculatedVca[key]) || '',
  compared: (calculatedComparedVca && calculatedComparedVca[key]) || '',
})

const getComparedData = ({ fields, t, calculatedVca, calculatedComparedVca }) => {
  const values = fields.$values()

  const mmConf = {
    comparedMessagePositive: t('thinner by'),
    comparedMessageNegative: t('thicker by'),
    unit: 'mm',
    comparing: true,
  }
  const keys = {
    weight: {
      title: t('weight'),
      comparedMessagePositive: t('lighter by'),
      comparedMessageNegative: t('heavier by'),
      unit: 'g',
      comparing: true,
    },
    centerThickness: {
      title: t('center thickness'),
      ...mmConf,
    },
    thinnestPointShape: {
      title: t('minEdgeThickness'),
      withShapeOnly: true,
      ...mmConf,
    },
    thickestPointShape: {
      title: t('maxEdgeThickness'),
      withShapeOnly: true,
      ...mmConf,
    },
    calculatedDiameter: {
      title: t('diameter'),
    },
    calculatedBaseCurve: {
      title: t('lens base'),
    },
  }

  if (values.orderType === orderTypes.DIAMETER_ONLY) {
    Object.keys(keys).forEach(k => {
      if (keys[k].withShapeOnly) {
        delete keys[k]
      }
    })
  }

  Object.keys(keys).forEach(k => {
    const data = keys[k]
    let comparedMessageR = ''
    let comparedMessageL = ''
    const r = getCalcValue(`${k}R`, calculatedVca, calculatedComparedVca)
    const l = getCalcValue(`${k}L`, calculatedVca, calculatedComparedVca)
    const diffR = r.original - r.compared || undefined
    const diffL = l.original - l.compared || undefined
    const diffPercentageR = diffR && (diffR / r.original) * 100
    const diffPercentageL = diffL && (diffL / r.original) * 100
    const betterR = !data.comparing ? '' : diffR > 0 ? 'compared' : 'original'
    const betterL = !data.comparing ? '' : diffL > 0 ? 'compared' : 'original'

    if (
      data.comparedMessagePositive &&
      data.comparing &&
      !isNaN(diffR) &&
      !isNaN(diffL) &&
      r.compared &&
      isFinite(diffPercentageR) &&
      isFinite(diffPercentageL)
    ) {
      comparedMessageR = `${
        diffR > 0 ? data.comparedMessagePositive : data.comparedMessageNegative
      } ${Math.abs(diffR).toFixed(2)}${data.unit} (${-diffPercentageR.toFixed(1)}%)`

      comparedMessageL = `${
        diffL > 0 ? data.comparedMessagePositive : data.comparedMessageNegative
      } ${Math.abs(diffL).toFixed(2)}${data.unit} (${-diffPercentageL.toFixed(1)}%)`
    }

    keys[k] = {
      ...data,
      comparedMessageR,
      comparedMessageL,
      r,
      l,
      diffR,
      diffL,
      diffPercentageR,
      betterR,
      diffPercentageL,
      betterL,
    }
  })
  return keys
}

const createCalculatedSet = ({ calculatedVca, calculatedComparedVca }) => {
  const calculatedPairKeys = [
    'weight',
    'centerThickness',
    'calculatedBaseCurve',
    'thinnestPointShape',
    'thickestPointShape',
    'thinnestPointLense',
    'thickestPointLense',
    'calculatedDiameter',
    'lenseOffsetX',
    'lenseOffsetY',
  ]
  const calculatedSingleKeys = ['d3', 'edgeThickness']

  const result = {}

  calculatedSingleKeys.forEach(k => {
    result[k] = {
      original: calculatedVca && calculatedVca[k],
      compared: calculatedComparedVca && calculatedComparedVca[k],
    }
  })
  calculatedPairKeys.forEach(k => {
    result[`${k}R`] = {
      original: calculatedVca && calculatedVca[`${k}R`],
      compared: calculatedComparedVca && calculatedComparedVca[`${k}R`],
    }
  })
  calculatedPairKeys.forEach(k => {
    result[`${k}L`] = {
      original: calculatedVca && calculatedVca[`${k}L`],
      compared: calculatedComparedVca && calculatedComparedVca[`${k}L`],
    }
  })

  return result
}

function PrintOrder({
  t,
  currentLensR,
  currentLensL,
  comparedLens,
  fields,
  calculatedVca,
  calculatedComparedVca,
  children,
  selectedVca,
  showCalculation,
  activeStep,
  disable,
}) {
  // do not render in step 1 and 2
  if ([1, 2].includes(activeStep) || disable) {
    return children
  }

  const comparedData = getComparedData({ t, fields, calculatedVca, calculatedComparedVca })
  const values = fields.$values()
  const calcedData = createCalculatedSet({ calculatedVca, calculatedComparedVca })

  const stockLensR = !!(
    currentLensR &&
    currentLensR.ranges &&
    currentLensR.ranges[0].range.stockLens
  )
  const stockLensL = !!(
    currentLensL &&
    currentLensL.ranges &&
    currentLensL.ranges[0].range.stockLens
  )
  const comparedStockLens = !!comparedLens?.stockLens

  const lenseOffsetYR = (stockLensR && calculatedVca && -calculatedVca.segmentDropR) || 0
  const lenseOffsetYL = (stockLensL && calculatedVca && -calculatedVca.segmentDropL) || 0
  const lenseOffsetXR = (stockLensR && calculatedVca && calculatedVca.totalInsetR) || 0
  const lenseOffsetXL = (stockLensR && calculatedVca && calculatedVca.totalInsetL) || 0

  const visualiserProps = showCalculation
    ? {
      calculatedDiameterR: calculatedVca && calculatedVca.calculatedDiameterR,
      calculatedDiameterL: calculatedVca && calculatedVca.calculatedDiameterL,
      calculatedDiameterER: calculatedVca && calculatedVca.calculatedDiameterER,
      calculatedDiameterEL: calculatedVca && calculatedVca.calculatedDiameterEL,
      edgeThickness: calcedData.edgeThickness,
      lenseOffsetXR,
      lenseOffsetXL,
      lenseOffsetYR,
      lenseOffsetYL,
      stockLensR,
      stockLensL,
      calcedData,
      comparedStockLens,
      showRadius: true,
      decXR: values.decXR,
      decYR: values.decYR,
      decXL: values.decXL,
      decYL: values.decYL,
      withoutShape: values.orderType === orderTypes.DIAMETER_ONLY,
    }
    : {
      withoutShape: values.orderType === orderTypes.DIAMETER_ONLY || !selectedVca,
      withBox: true,
      withDbl: true,
      showMultifocal: true,
      fitsRadius: true,
      multifocalConfig: getMultifocalConfig(currentLensR, values.proglen),
      multifocalConfigL: getMultifocalConfig(currentLensL, values.proglen),
      showBifocal: true,
      bifocalConfigR: getBifocalConfig(currentLensR),
      bifocalConfigL: getBifocalConfig(currentLensL),
    }

  return (
    <>
      <PrintContent>
        <Summary
          values={values}
          currentLensL={currentLensL}
          currentLensR={currentLensR}
          vca={selectedVca}
          isCreatingOrder={!values._id}
          isCalculationSummary={showCalculation}
          isPrint
        />
        {values.orderType !== orderTypes.DIAMETER_ONLY && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Visualiser
              {...selectedVca}
              showPupil
              pdR={values.pdR}
              pdL={values.pdL}
              heightR={values.heightR}
              heightL={values.heightL}
              disableL={!values.leftLensEnabled}
              disableR={!values.rightLensEnabled}
              width="715"
              height="308"
              hideToggleButton
              noBorder
              hidden={values.orderType !== orderTypes.DIAMETER_ONLY && !selectedVca}
              isPrint
              {...visualiserProps}
            />
          </div>
        )}
        {showCalculation && (
          <>
            <Table>
              <TableRow withBackground>
                <TableCell width="17rem" withBackground />
                <TableCell borderLeft large align="center">
                  <Dot /> {t('original lens')}
                </TableCell>
                <TableCell large align="center">
                  <>
                    <Dot compared /> {t('comparison lens')}
                  </>
                </TableCell>
                <TableCell borderLeft large align="center">
                  <Dot /> {t('original lens')}
                </TableCell>
                <TableCell large align="center">
                  <>
                    <Dot compared /> {t('comparison lens')}
                  </>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="17rem" align="flex-end" textAlign="right" withBackground>
                  {t('lens type')}
                </TableCell>
                <TableCell borderLeft>
                  {currentLensR && currentLensR.names && currentLensR.names[0].long}
                </TableCell>
                <TableCell align={!comparedLens && 'center'}>
                  {comparedLens && comparedLens.names && comparedLens.names[0].long}
                </TableCell>
                <TableCell borderLeft>
                  {currentLensL && currentLensL.names && currentLensL.names[0].long}
                </TableCell>
                <TableCell align={!comparedLens && 'center'}>
                  {comparedLens && comparedLens.names && comparedLens.names[0].long}
                </TableCell>
              </TableRow>
              {Object.keys(comparedData).map(k => {
                const data = comparedData[k]
                // todo duplicated code in CreateOrderStep3
                const decimals = k === 'calculatedBaseCurve' ? 2 : 1
                return (
                  <TableRow key={k}>
                    <TableCell width="17rem" align="flex-end" textAlign="right" withBackground>
                      {data.title}
                    </TableCell>
                    <TableCell bold={data.betterR === 'original'} borderLeft>
                      {roundNumber(data.r.original, decimals)}
                    </TableCell>
                    <TableCell bold={data.betterR === 'compared'}>
                      {roundNumber(data.r.compared, decimals)}
                      {data.comparedMessageR && (
                        <TableCellComparedMessage>
                          {' '}
                          - {data.comparedMessageR}
                        </TableCellComparedMessage>
                      )}
                    </TableCell>
                    <TableCell bold={data.betterL === 'original'} borderLeft>
                      {roundNumber(data.l.original, decimals)}
                    </TableCell>
                    <TableCell bold={data.betterL === 'compared'}>
                      {roundNumber(data.l.compared, decimals)}
                      {data.comparedMessageL && (
                        <TableCellComparedMessage>
                          {' '}
                          - {data.comparedMessageL}
                        </TableCellComparedMessage>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </>
        )}
      </PrintContent>
      <HiddenInPrint>{children}</HiddenInPrint>
    </>
  )
}

PrintOrder.propTypes = {
  t: PropTypes.func.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  comparedLens: PropTypes.object,
  fields: PropTypes.object.isRequired,
  calculatedVca: PropTypes.object,
  calculatedComparedVca: PropTypes.object,
  children: PropTypes.node.isRequired,
  selectedVca: PropTypes.object,
  showCalculation: PropTypes.bool,
}

PrintOrder.defaultProps = {
  currentLensR: null,
  currentLensL: null,
  comparedLens: null,
  calculatedVca: null,
  calculatedComparedVca: null,
  selectedVca: null,
  showCalculation: false,
}

const enhance = compose(
  connect(({ catalog, vca }) => ({
    currentLensR: catalog.currentLensR,
    currentLensL: catalog.currentLensL,
    comparedLens: vca.comparedLens,
    calculatedVca: vca.calculatedVca,
    calculatedComparedVca: vca.calculatedComparedVca,
    selectedVca: vca.selectedVca,
  })),
  withTranslation(),
)

export default enhance(PrintOrder)
