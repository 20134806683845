import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000052;
  color: white;
  padding: 10rem;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`

const ErrorScreen = ({ issueId }) => (
  <Wrapper>
    <h1>Oh, some error happened! :(</h1>
    <h2>
      But stay calm, we have already assigned a team of highly trained
      developers to solve this issue. 🐒
    </h2>
    <br />
    <p>In the meantime, you can try:</p>
    <ul>
      <li>Refresh this page.</li>
      <li>Try again in 30 minutes.</li>
      <li>Send us an email or call us.</li>
    </ul>
    <br />
    {issueId && <p>Issue ID: {issueId}</p>}
  </Wrapper>
)

export default ErrorScreen
