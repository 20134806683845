import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import {
  R,
  L,
  lensOptionsSelectTypes,
  orderTypes,
  appTypeConfig,
  currenciesTexts,
} from '../../../../common/config'
import { getLensValuesBySide, transformPricesArrayToObject } from '../../../../common/helpers'
import { fetchLensPrice } from '../../../../common/catalog/actions'
import { fetchOrderPrices } from '../../../../common/orders/actions'
import { HiddenInPrint, Box, Row, Col, Gap } from '../..'
import { SummaryTable, SummaryTitle } from '..'
import { constants } from '../../../../common/schemaConfig'
import { getOptionValue as getOptionValueHelper } from '../../../app/helpers'
import { getFinalRadiiByShapeAndLenses } from '../../../../common/lib/vca'

const dataColumnConfig = {
  width: '16rem',
  textAlign: 'right',
}

const getPriceString = (value, currency) =>
  `${value.toFixed(2)} ${currenciesTexts[currency] || currency}`

const getPriceSum = (priceValues = {}) => {
  const sum = Object.keys(priceValues).reduce((result, key) => {
    const { value } = priceValues[key]
    if (typeof value === 'number') {
      result += value
    }
    return result
  }, 0)

  const withCurrencyKey = Object.keys(priceValues).find(key => !!priceValues[key].currency)
  const { currency = '' } = priceValues[withCurrencyKey] || {}

  return getPriceString(sum, currency)
}

const getOptionPrices = ({ optionId, optionType, optionsArray }) => {
  if (!optionId) return null
  const optionsObject = optionsArray?.find(({ selectType }) => selectType === optionType) || null
  if (!optionsObject) return null
  if (!optionsObject.options) {
    console.notifyWarning(
      new Error('Undefined options', { optionsObject, optionId, optionType, optionsArray }),
    )
  }
  const option = optionsObject.options.find(({ _id, originalId }) => {
    const compareValue = optionType === lensOptionsSelectTypes.CTO ? originalId : _id
    return compareValue === optionId
  })
  if (!option) return null
  const optionPrices = option.prices
  return optionPrices
}

const getPriceObject = (prices, priceType) => {
  if (!prices) return {}
  const price = prices[priceType]
  if (!price) return {}
  return {
    value: price.value,
    stringValue: getPriceString(price.value, price.currency),
    currency: price.currency,
  }
}

class PricesSection extends Component {
  async componentDidMount() {
    const {
      values,
      fetchLensPrice,
      isCreatingOrder,
      selectedVca,
      currentLensL = {},
      currentLensR = {},
    } = this.props

    if (!appTypeConfig.hasPriceList) return
    if (!isCreatingOrder) return

    const radius =
      selectedVca &&
      getFinalRadiiByShapeAndLenses({
        vca: selectedVca,
        lensR: currentLensR,
        lensL: currentLensL,
        order: values,
      })

    const promises = []
    if (values.lensR) {
      const queryR = {
        ...getLensValuesBySide(values).r,
      }
      if (!queryR.diameterPhysical && radius?.r) queryR.diameterPhysical = radius.r * 2
      const promise = fetchLensPrice(values.lensR, R, queryR).meta.action.payload
      promises.push(promise)
    }
    if (values.lensL) {
      const queryL = {
        ...getLensValuesBySide(values).l,
      }
      if (!queryL.diameterPhysical && radius?.l) queryL.diameterPhysical = radius.l * 2
      const promise = fetchLensPrice(values.lensL, L, queryL)
      promises.push(promise)
    }
    await Promise.all(promises)
  }

  getOptionValue = ({ coatingType, side, fieldValue }) => {
    const { currentLensL, currentLensR } = this.props
    const lens = side === R ? currentLensR : currentLensL
    return getOptionValueHelper({ fieldValue, coatingType, lens })
  }

  getPrice = ({ side, fieldValue, selectType, savedPrices = [] }) => {
    const { currentLensL, currentLensR, priceType, isCreatingOrder } = this.props
    const currentLens = side === R ? currentLensR : currentLensL
    const prices =
      isCreatingOrder && currentLens
        ? getOptionPrices({
          optionId: fieldValue,
          optionType: selectType,
          optionsArray: currentLens.optionsArray,
        })
        : transformPricesArrayToObject(savedPrices)

    return getPriceObject(prices, priceType)
  }

  renderSummaryTable = ({ side, pricesValues }) => {
    const { currentLensL, currentLensR, values, isCreatingOrder, t } = this.props
    const getLensName = (value, side) => {
      if (!value) return '---'
      const lens = side === R ? currentLensR : currentLensL
      const name = lens && lens.names ? lens.names[0].long : values[`lensName${side}`]
      return name
    }

    const getOptionDataConfig = (optionType = '') => {
      const fieldKey = optionType.toLowerCase() // nemusí fungovat vždy
      if (!constants[fieldKey]) return '---'
      const header = !isCreatingOrder
        ? values[`${fieldKey}Name${side}`]
        : this.getOptionValue({
          coatingType: optionType,
          side,
          fieldValue: values[`${fieldKey}${side}`],
        })
      const fieldName = `${fieldKey}${side}`
      return {
        header,
        fieldName,
      }
    }

    const data = [
      {
        header: isCreatingOrder
          ? getLensName(values[`lens${side}`], side)
          : values[`lensName${side}`],
        fieldName: `${constants.lens}${side}`,
      },
      { ...getOptionDataConfig(lensOptionsSelectTypes.COATING) },
      { ...getOptionDataConfig(lensOptionsSelectTypes.COLOR) },
      { ...getOptionDataConfig(lensOptionsSelectTypes.CTO) },
      { ...getOptionDataConfig(lensOptionsSelectTypes.ECS) },
    ].filter(({ header, fieldName }) => header && pricesValues[fieldName].value)

    const tableValues = Object.keys(pricesValues).reduce((result, key) => {
      result[key] = pricesValues[key].stringValue
      return result
    }, {})

    return (
      <>
        <SummaryTable
          values={tableValues}
          isDisplayedInColumn
          columnConfig={{
            header: {
              // grow: 1,
              width: 'auto',
            },
            data: dataColumnConfig,
          }}
          data={data}
        />
        <Gap />
        <SummaryTable
          values={{ sideSum: getPriceSum(pricesValues) }}
          isDisplayedInColumn
          columnConfig={{
            header: {
              width: 'auto',
              style: {
                fontWeight: 700,
              },
            },
            data: dataColumnConfig,
          }}
          data={[
            {
              header: side === L ? t('reize; Price sum left') : t('reize; Price sum right'),
              fieldName: 'sideSum',
            },
          ]}
        />
      </>
    )
  }

  render() {
    const {
      t,
      values,
      currentLensL,
      currentLensR,
      isPriceShown,
      priceType,
      isCreatingOrder,
      fetchOrderPrices,
      currentOrderPrices,
    } = this.props
    console.log('RENDER SUMMARY currentOrderPrices', currentOrderPrices)
    // return (
    //   <>
    //     {currentOrderPrices && <div>yes</div>}
    //     <div
    //       onClick={() => fetchOrderPrices({ order: values })}
    //       style={{ marginBottom: '100px' }}
    //     >
    //       TEST PRICES
    //     </div>
    //   </>
    // )

    // if (!appTypeConfig.hasPriceList || !isPriceShown) return null

    const lensPricesR =
      currentLensR && isCreatingOrder
        ? currentLensR.prices
        : transformPricesArrayToObject(values.lensPricesR)

    const lensPricesL =
      currentLensL && isCreatingOrder
        ? currentLensL.prices
        : transformPricesArrayToObject(values.lensPricesL)

    const pricesValuesR = values.rightLensEnabled && {
      lensR: getPriceObject(lensPricesR, priceType),
      coatingR: this.getPrice({
        side: R,
        fieldValue: values.coatingR,
        selectType: lensOptionsSelectTypes.COATING,
        savedPrices: values.coatingPricesR,
      }),

      colorR: this.getPrice({
        side: R,
        fieldValue: values.colorR,
        selectType: lensOptionsSelectTypes.COLOR,
        savedPrices: values.colorPricesR,
      }),
      ctoR:
        values.orderType !== orderTypes.DIAMETER_ONLY &&
        values.isCtoCapableR &&
        this.getPrice({
          side: R,
          fieldValue: values.ctoR,
          selectType: lensOptionsSelectTypes.CTO,
          savedPrices: values.ctoPricesR,
        }),
      ecsR: this.getPrice({
        side: R,
        fieldValue: values.ecsR,
        selectType: lensOptionsSelectTypes.ECS,
        savedPrices: values.ecsPricesR, // todo - přidat uloženou cenu do db
      }),
    }
    const pricesValuesL = values.leftLensEnabled && {
      lensL: getPriceObject(lensPricesL, priceType),
      coatingL: this.getPrice({
        side: L,
        fieldValue: values.coatingL,
        selectType: lensOptionsSelectTypes.COATING,
        savedPrices: values.coatingPricesL,
      }),
      colorL: this.getPrice({
        side: L,
        fieldValue: values.colorL,
        selectType: lensOptionsSelectTypes.COLOR,
        savedPrices: values.colorPricesL,
      }),
      ctoL:
        values.orderType !== orderTypes.DIAMETER_ONLY &&
        values.isCtoCapableL &&
        this.getPrice({
          side: L,
          fieldValue: values.ctoL,
          selectType: lensOptionsSelectTypes.CTO,
          savedPrices: values.ctoPricesL,
        }),
      ecsL: this.getPrice({
        side: L,
        fieldValue: values.ecsL,
        selectType: lensOptionsSelectTypes.ECS,
        savedPrices: values.ecsPricesL, // todo - přidat uloženou cenu do db
      }),
    }

    const totalSum = getPriceSum({ ...pricesValuesR, ...pricesValuesL })
    const isLoading =
      (values.rightLensEnabled && !lensPricesR) || (values.leftLensEnabled && !lensPricesL)
    return (
      <HiddenInPrint>
        <Box>
          <Box.Header>
            <Box.Title>{t('reize; Prices')}</Box.Title>
          </Box.Header>
          <Box.Content>
            {isLoading
              ? <Row>{t('Loading')}</Row>
              : (
                <>
                  <Row>
                    <Col width="50%">
                      {values.rightLensEnabled && (
                      <>
                        <SummaryTitle>{t('right lens')}</SummaryTitle>
                        {this.renderSummaryTable({ side: R, pricesValues: pricesValuesR })}
                      </>
                      )}
                    </Col>
                    <Col width="50%">
                      {values.leftLensEnabled && (
                      <>
                        <SummaryTitle>{t('left lens')}</SummaryTitle>
                        {this.renderSummaryTable({ side: L, pricesValues: pricesValuesL })}
                      </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col width="100%">
                      <SummaryTable
                        values={{ totalSum }}
                        isDisplayedInColumn
                        columnConfig={{
                          header: {
                            // grow: 1,
                            width: 'auto',
                            style: {
                              fontWeight: 700,
                            },
                          },
                          data: dataColumnConfig,
                        }}
                        data={[
                          {
                            header: t('reize; Total price'),
                            fieldName: 'totalSum',
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </>
              )}
          </Box.Content>
        </Box>
      </HiddenInPrint>
    )
  }
}

PricesSection.defaultProps = {
  currentLensR: null,
  currentLensL: null,
  isCreatingOrder: false,
  selectedVca: null,
  currentOrderPrices: null,
}

PricesSection.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  fetchLensPrice: PropTypes.func.isRequired,
  isPriceShown: PropTypes.bool.isRequired,
  priceType: PropTypes.string.isRequired,
  isCreatingOrder: PropTypes.bool,
  selectedVca: PropTypes.object,
  fetchOrderPrices: PropTypes.func.isRequired,
  currentOrderPrices: PropTypes.object,
}

const enhance = compose(
  connect(
    ({ catalog = {}, vca = {}, config = {}, orders = {} }) => ({
      selectedVca: vca.selectedVca,
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      isPriceShown: config.isPriceShown,
      priceType: config.priceType,
      currentOrderPrices: orders.currentOrderPrices,
    }),
    {
      fetchLensPrice,
      fetchOrderPrices,
    },
  ),
  withTranslation(),
)

export default enhance(PricesSection)
