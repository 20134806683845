import { bevelPositions, frameTypes } from '../config'

// TODO - zatím pouze kopie omegy - potřeba vše zkontrolovat a přenastavit

export default {
  APP_TYPE: 'jaikudo',
  isSummaryTooltipEnabled: true,
  isCanCreateOrdersForOthersEnabled: true,
  isOrderCancellationEnabled: true,
  hasLogoutInHeader: true,
  orderCancellationTime: 60,
  isBifocalVisualisationEnabled: false,
  showShapeGeometryCenterAtStep2: true,
  showFooterImpressum: false,
  showFooterGdprDoc: false,
  showFooterLegalDoc: false,
  tracerManualCustomLanguages: ['pl'], // use customized tracer manual for these languages
  milensPrivacyPolicyCustomLanguages: ['pl', 'en', 'cs', 'de'], // use customized milens privacy policy for these languages
  showOrdersTableIdColumn: false,
  hideOrdersSourceSwitch: false, // hide order source switch on HP
  useNativeCookieConsent: true,
  defaultCopyReferenceOnDuplication: false,
  safetyMarginStock: 1.5, // value added to radius
  safetyMarginManufacturer: 1.5, // value added to radius
  isMiLensEnabled: true,
  orderSanitizeStringsVariant: 'FULL', // FULL or SOFTER
  areFtpDeliveryNotesEnabled: true,
  hostConf: {
    isGoToWowEnabled: false,
    wowUrl: '',
    htmlTitle: 'LensNet',
    logo: {
      publicPath: '/public/images/logo_lensnet.png',
      styles: {
        image: {
          maxHeight: '3.5rem',
          position: 'relative',
          top: '-6px',
        },
      },
    },
    logoSquared: {
      publicPath: '/public/images/logo_lensnet.png',
    },
    favicon: {
      publicPath: '/public/favicon-jaikudo.png',
    },
    areDeliveryNotesEnabled: true,
    isGetOrderPriceEnabled: true,
  },
  uniqueTranslationKeyPrefix: 'jaikudo;',
  versionPrefix: 'jk',
  isGoToWowEnabled: false,
  languages: ['pl', 'en', 'cs', 'de'],
  isCustomDiameterEnabled: true,
  isEllipticalDiameterEnabled: false,
  // considerDesignShift: false, // in lens diameter calculation (getFinalRadiiByShapeAndLenses) - not used anymore
  isLensBaseEnabled: true, // base curve field in refractions section
  // select base curve field after diameter selection with options from catalog
  isBaseCurveSelectEnabled: false,
  isSphAndCylInHundredthsEnabled: false,
  sphAndCylInHundredthsGroups: [],
  isWithShapeOnlyLensesEnabled: false,
  showCalcSurfacePoints: true,
  isExpressOrderEnabled: true,
  isExpress48Enabled: true,
  isCenterThicknessEnabled: false,
  step2DefaultBevelPositionForEdgingOrder: undefined,
  step2AutoPolish: true, // in step 2, automatically set polish field based on frame type
  // let user decide wether spectacles (only) lenses should be polished, customizable via group
  isSpectaclesPolishEnabled: false,
  isPinBevelEnabled: true,
  areFramesEnabled: false,
  isUvSelectionEnabled: true,
  isDecentrationEnabled: true,
  isSoapEnabled: true,
  isSortLensOptionsByNameEnabled: true,
  isMandatoryColorPreselectEnabled: true,
  soapWsdlDev: 'order-import-omega-dev', // deprecated
  soapWsdl: 'order-import-omega', // deprecated
  soapDevUrl: 'https://soap.omega.azure.virtii.com',
  soapProdUrl: 'https://soap.omegadirect.cz',
  isTracerEnabledByDefault: true,
  isFrameCurveStep2Visible: true,

  arePromotionsEnabled: false,
  orderTypesWithHiddenStandardShapes: ['EDGING'],
  isRemoteLogoutEnabled: true,
  isAccountInfoVisible: true,
  // enables fetching order prices from LW soap server
  // isGetOrderPriceEnabled: true must be added to hostConf
  isGetPricesFromLWSoapEnabled: true,

  // enables edging without shape order type
  // internally represented as DIAMETER_ONLY + isEdgingWithoutShape = true
  // it acts as DIAMETER_ONLY order type, but pd and height must be set
  isEdgingWithoutShapeEnabled: true,
  colors: {
    primary: '#46986F',
    titleColor: '#46986F',
  },
  orderSchemaOverride: {
    singleKeys: {
      reference: {
        field: {
          maxLength: 30,
        },
      },
      clientName: {
        field: {
          maxLength: 30,
        },
      },
    },
  },
  lensFocusTypes: {
    RX: {
      A: 'Single vision',
      B: 'Bifocal',
      C: 'Office',
      D: 'Readlax',
    },
    Progressive: {
      E: 'HV Simple Plus',
      F: 'HV Light',
      G: 'HV Lifestyle',
      H: 'HV Identity 2',
      N: 'HV 4EVO',
    },
    Actidrive: {
      I: 'SingleVision',
      J: 'Progressive',
      K: 'SingleVision',
    },
  },

  // deprecated, used as fallback
  // set it in keystone frameBrand
  suppliersMap: {
    OME: 'OME',
    '4EY': '4 Eyes',
  },
  getBevelPositions: () => [
    bevelPositions.AUTO,
    bevelPositions.FRONT,
    bevelPositions.PERCENT,
    bevelPositions.HALF,
    bevelPositions.BACK,
    bevelPositions.FRAMECURVE,
  ],
  getFrameTypes: () => [
    frameTypes.METAL,
    frameTypes.PLASTIC,
    frameTypes.NYLOR,
    frameTypes.DRILLED,
    frameTypes.OPTYL,
    // frameTypes.SPECIAL,
    // frameTypes.GROOVEDDRILLED,
  ],

  getPolishDisabledWithFrameTypes: () => [frameTypes.METAL, frameTypes.OPTYL, frameTypes.PLASTIC],

  individualMenus: {
    FFURCL: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 16,
            label: 'Shallow',
          },
          {
            value: 18,
            label: 'Regular',
          },
          {
            value: 20,
            label: 'Deep',
          },
        ],
      },
    },
    FFURJL: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: '1.3m',
          },
          {
            value: 2,
            label: '2m',
          },
          {
            value: 4,
            label: '4m',
          },
        ],
      },
    },
    FFURKL: {
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: '1.3m',
          },
          {
            value: 2,
            label: '2m',
          },
          {
            value: 4,
            label: '4m',
          },
        ],
      },
    },
    FFURTL: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 16,
            label: 'Shallow',
          },
          {
            value: 18,
            label: 'Regular',
          },
        ],
      },
    },
    FFURRL: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: 'INDIVIDUAL',
          },
          {
            value: 14,
            label: '14',
          },
          {
            value: 15,
            label: '15',
          },
          {
            value: 16,
            label: '16',
          },
          {
            value: 17,
            label: '17',
          },
          {
            value: 18,
            label: '18',
          },
          {
            value: 19,
            label: '19',
          },
          {
            value: 20,
            label: '20',
          },
        ],
      },
    },
    FFURPL: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: 'INDIVIDUAL',
          },
          {
            value: 14,
            label: '14',
          },
          {
            value: 15,
            label: '15',
          },
          {
            value: 16,
            label: '16',
          },
          {
            value: 17,
            label: '17',
          },
          {
            value: 18,
            label: '18',
          },
          {
            value: 19,
            label: '19',
          },
          {
            value: 20,
            label: '20',
          },
        ],
      },
    },
    FFURIL: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: 'INDIVIDUAL',
          },
          {
            value: 14,
            label: '14',
          },
          {
            value: 15,
            label: '15',
          },
          {
            value: 16,
            label: '16',
          },
          {
            value: 17,
            label: '17',
          },
          {
            value: 18,
            label: '18',
          },
          {
            value: 19,
            label: '19',
          },
          {
            value: 20,
            label: '20',
          },
        ],
      },
    },
    FFURNL: {
      proglen: {
        isRequired: true,
        options: [
          {
            value: 16,
            label: 'Shallow',
          },
          {
            value: 18,
            label: 'Regular',
          },
          {
            value: 20,
            label: 'Deep',
          },
        ],
      },
    },
    FFURGL: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 15,
            label: 'Ultra 15',
          },
          {
            value: 17,
            label: 'Shallow 17',
          },
          {
            value: 20,
            label: 'Regular 20',
          },
          {
            value: 22,
            label: 'Deep 22',
          },
        ],
      },
    },
    FFURAL: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
    },
    FFURFL: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
    },
    FFURXL: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 5,
            label: '14 / 0,50',
          },
          {
            value: 7,
            label: '14 / 0,75',
          },
          {
            value: 10,
            label: '14 / 1,00',
          },
          {
            value: 12,
            label: '14 / 1,25',
          },
          {
            value: 15,
            label: '18 / 0,50',
          },
          {
            value: 17,
            label: '18 / 0,75',
          },
          {
            value: 20,
            label: '18 / 1,00',
          },
          {
            value: 22,
            label: '18 / 1,25',
          },
        ],
      },
    },
    FFURYL: {
      proglen: {
        isRequired: true,
        options: [
          {
            value: 5,
            label: '14 / 0,50',
          },
          {
            value: 7,
            label: '14 / 0,75',
          },
          {
            value: 10,
            label: '14 / 1,00',
          },
          {
            value: 12,
            label: '14 / 1,25',
          },
          {
            value: 15,
            label: '18 / 0,50',
          },
          {
            value: 17,
            label: '18 / 0,75',
          },
          {
            value: 20,
            label: '18 / 1,00',
          },
          {
            value: 22,
            label: '18 / 1,25',
          },
        ],
      },
    },
    FFURBL: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
    },
    FFURDL: {
      proglen: {
        isRequired: true,
        options: [
          {
            value: 7,
            label: '0,75',
          },
          {
            value: 12,
            label: '1,25',
          },
        ],
      },
    },
    FFUFEV: {
      bvd: {
        isRequired: false,
        min: 6,
        max: 30,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 35,
      },
      panto: {
        isRequired: false,
        min: -5,
        max: 30,
      },
    },
    FFUSTY: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 12,
            label: 'Shallow',
          },
          {
            value: 14,
            label: 'Regular',
          },
          {
            value: 16,
            label: 'Deep',
          },
        ],
      },
    },
    FFUPRT: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 16,
            label: 'Shallow',
          },
          {
            value: 18,
            label: 'Regular',
          },
        ],
      },
    },
    FFUAST: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: '1.3m',
          },
          {
            value: 2,
            label: '2m',
          },
          {
            value: 4,
            label: '4m',
          },
        ],
      },
    },
    FFUDRV: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
    },
    FFULTE: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: 'INDIVIDUAL',
          },
          {
            value: 12,
            label: '14',
          },
          {
            value: 13,
            label: '15',
          },
          {
            value: 14,
            label: '16',
          },
          {
            value: 15,
            label: '17',
          },
          {
            value: 16,
            label: '18',
          },
        ],
      },
    },
    FFUPRO: {
      bvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: true,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: 'INDIVIDUAL',
          },
          {
            value: 12,
            label: '14',
          },
          {
            value: 13,
            label: '15',
          },
          {
            value: 14,
            label: '16',
          },
          {
            value: 15,
            label: '17',
          },
          {
            value: 16,
            label: '18',
          },
        ],
      },
    },
    FFUSPL: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 12,
            label: 'Shallow',
          },
          {
            value: 14,
            label: 'Regular',
          },
          {
            value: 16,
            label: 'Deep',
          },
        ],
      },
    },
    FFUFFM: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
    },
    FFUARX: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: '0,50',
          },
          {
            value: 2,
            label: '0,75',
          },
          {
            value: 3,
            label: '1,00',
          },
          {
            value: 4,
            label: '1,25',
          },
        ],
      },
    },
    FFUBAT: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
    },
    FFUADW: {
      bvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 45,
      },
      panto: {
        isRequired: false,
        min: -8,
        max: 25,
      },
      proglen: {
        isRequired: true,
        options: [
          {
            value: 1,
            label: '0,75',
          },
          {
            value: 2,
            label: '1,25',
          },
        ],
      },
    },
  },
}
