export const questions = [
  {
    name: 'usedProgressiveLenses',
    text: 'Has your client worn progressive lenses before?',
  },
  {
    name: 'intermediateArea',
    text: 'Will he/she use the intermediate area more than 4 h/day? (computer, tv, cooking, cleaning)?',
  },
  {
    name: 'nearVisionArea',
    text: 'Will he/she use the near vision area more than 4 h/day? (reading, writing, sewing, precision work)?',
  },
  {
    name: 'distanceArea',
    text: 'Will he/she use the distance area more than 4 h/day? (driving, walking, playing sports)?',
  },
  // {
  //   name: 'mobiZone',
  //   text: 'MobiZone option - only for people who use mobile devices very intensively.',
  // },
]

const UDB = [
  [1, 1, 1, 1],
  [1, 1, 0, 0],
  [1, 0, 1, 1],
  [1, 0, 0, 0],
  [0, 0, 1, 1],
]

const UDD = [
  [1, 1, 0, 1],
  [1, 0, 0, 1],
  [0, 0, 0, 1],
]

const UDN = [
  [1, 1, 1, 0],
  [1, 0, 1, 0],
  [0, 0, 1, 0],
]

const UDF = [
  [0, 1, 1, 1],
  [0, 1, 1, 0],
  [0, 1, 0, 1],
  [0, 1, 0, 0],
  [0, 0, 0, 0],
]

const defaultResult = 'UDB'

export const evaluationMap = {
  UDB,
  UDD,
  UDN,
  UDF,
}

export default {
  questions,
  evaluationMap,
  defaultResult,
  title: 'Select frequency of use of vision areas',
}
