import React from 'react'
import { RouterProvider } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import App from './App'
import * as pages from '../pages'
import { RouterErrorFallback } from '../components/errorFallback'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <RouterErrorFallback />,
    children: [
      {
        path: 'auth',
        element: <pages.AuthPage />,
      },
      {
        path: 'auth/jaikudo-oauth',
        element: <pages.OAuthPage />,
      },
      {
        path: '',
        element: <pages.AuthorizedPage />,
        children: [
          {
            path: '',
            element: <pages.OrdersListPage />,
          },

          {
            path: 'create-order/:step',
            element: <pages.CreateOrderPage />,
          },
          {
            path: 'settings',
            element: <pages.SettingsPage />,
          },
          {
            path: 'promotions',
            element: <pages.PromotionsPage />,
          },
          {
            path: 'impressum',
            element: <pages.OwnerPage />,
          },
          {
            path: '/order-detail/:id',
            element: <pages.OrderDetailPage />,
          },
          {
            path: '/edit-order/:id',
            element: <pages.OpenImportedOrderPage />,
          },
        ],
      },
      { path: '*', element: <pages.NotFoundPage /> },
    ],
  },
])

function Router() {
  return <RouterProvider router={router} fallbackElement={<Fallback />} />
}

export default Router

const Fallback = () => <div>Loading router</div>
